<template>
  <div class="header d-flex justify-space-between align-center">
    <div class="cop-title d-flex justify-center align-center">
      <h2 class="cop-h2 text-md-h3 text-sm-h4 text-xs-h4 d-flex justify-center flex-column">
        <!-- <img
          height="30px"
          :src="imgLogo"
        > -->
        <div class="d-flex">
          <div class="cop-title-left">
            {{ $i18n.locale == 'en' ? 'Meet us at' : '相约' }}
          </div>
          <strong>COP<span class="cop-green">26</span></strong>
        </div>
        <div class="cop-subtitle">
          {{ $i18n.locale == 'en' ? 'Together for Our Planet' : '一起为了我们的星球' }}
        </div>
      </h2>
    </div>
    <img
      :height="imgHeight"
      :width="imgHeight"
      :src="imgSrc"
    >
  </div>
</template>

<script>
  import { mdiNoteEditOutline } from '@mdi/js'
  export default {
    name: 'SectionCop26Header',
    data: () => ({
      icon: mdiNoteEditOutline,
      imgSrc: require('@/assets/zcp/bg-cop26.jpeg'),
    }),
    computed: {
      imgHeight() {
        const point = this.$vuetify.breakpoint.name
        let height
        switch (point) {
          case 'xl':
            height = '800px'
            break
          case 'lg':
            height = '600px'
            break
          case 'md':
            height = '600px'
            break
          case 'sm':
            height = '400px'
            break
          case 'xs':
            height = '400px'
            break
        }
        return height
      },
    },
  }
</script>

<style lang="sass" scoped>
.header
  height: 40vh
  background-color: #38318c
  overflow: hidden
  padding-left: 20px
  .cop-title
    z-index: 2
    width: 50%
    .cop-h2
      padding: 20px
      // font-size: 40px
      font-weight: 400
      color: white
      .cop-title-left
        white-space: nowrap
      strong
        margin-left: 10px
      .cop-green
        color: #8cdb73
  .cop-subtitle
    font-size: 24px
</style>
